<template>
	<v-container style="padding:0px;">
		<v-card-title>SKILL</v-card-title>
		<v-row>
      <v-col sm="6">
        <v-row class="px-4 pt-1"
          v-for="(item, i) in items1"
          :key="i">
          <v-row class="pl-7"><p class="ma-0 font-weight-light">{{item.title}}</p></v-row>
          <v-progress-linear 
            class="pl-4"
            v-model="item.score"
            color="deep-purple darken-2"
          ></v-progress-linear>
        </v-row>
			</v-col>
      <v-col sm="6">
        <v-row class="px-4 pt-1"
          v-for="(item, i) in items2"
          :key="i">
          <v-row class="pl-7"><p class="ma-0 font-weight-light">{{item.title}}</p></v-row>
          <v-progress-linear 
            class="pl-4"
            v-model="item.score"
            color="deep-purple darken-2"
          ></v-progress-linear>
        </v-row>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
export default {

  data: () => ({
    items1: [
      {
        title: ".Net / .Net Core",
        score: 80
      },
      {
        title: "Go",
        score: 70
      },
      {
        title: "Python",
        score: 80
      },
      {
        title: "Vue.js",
        score: 50
      },
      {
        title: "Robot Framework (Selenium)",
        score: 60
      },
      {
        title: "ELK Stack",
        score: 70
      }
    ],
    items2: [
      {
        title: "GCP",
        score: 65
      },
      {
        title: "AWS",
        score: 50
      },
      {
        title: "Azure",
        score: 50
      },
      {
        title: "SQL (Mysql, SQL Server, Oracle, DB2)",
        score: 80
      },
      {
        title: "NoSQL (Mongo, Cassandra)",
        score: 75
      },
      {
        title: "CI/CD (Jenkins)",
        score: 50
      }
    ]
})
}
</script>
<style scoped>
  .mylist > * {
    margin-left: 0px;
  }
  .mycontainer {
    padding: 0px;
  }
  .padleftright {
    padding: 20px;
  }
  .padright {
    padding: 20px;
  }
</style>