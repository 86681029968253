<template>
  <v-app>
    <v-content class="viewpoint">
      <info/>
      <timeline/>
    </v-content>
  </v-app>
</template>

<script>
import info from './components/info';
import timeline from './components/timeline';
import 'flag-icon-css/css/flag-icon.css'

export default {
  name: 'App',

  components: {
    info,
    timeline,
  },

  data: () => ({
    //
  }),
};
</script>

<style scoped>
  @import './assets/css/common.css';
</style>