<template>
	<v-container style="padding:0px;">
		<v-card-title>EXPERIENCE</v-card-title>
		<v-row v-for="(item,i) in experiences" :key="i">
			<v-col sm="6" class=pt-0>
				<v-list-item two-line>
					<v-list-item-content class="py-0">
						<v-list-item-title>{{ item.title }}</v-list-item-title>
						<v-list-item-subtitle>{{ item.subtitle1 }}</v-list-item-subtitle>
						<v-list-item-subtitle>{{ item.subtitle2 }}</v-list-item-subtitle>
					</v-list-item-content>
				</v-list-item>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
export default {
	data: () => ({
		experiences: [
			{
				"title": "Krung Thai Bank Public Company Limited",
				"subtitle1": "Senior Officer (IT Innovation Lab Department)",
				"subtitle2": "2019 - Present"
			},
			{
				"title": "Mycos Technologies Co.,Ltd.",
				"subtitle1": "Senior Software Engineer",
				"subtitle2": "2011 - 2019"
			},
			{
				"title": "Nim See Seng Transport 1988 Co.,Ltd.",
				"subtitle1": "Junior Programmer",
				"subtitle2": "2009 - 2011"
			}
		]
		
	})
}
</script>