<template>
	<v-container class="infocontainer">
		<v-row style="background-color:#ded7dd;">
			<v-col sm="4" class="pa-0">
					<v-card
						class="pa-1 pt-4"
						tile
						outlined
            min-width="320px"
						width="100%"
						height="100%"
					>
					<v-row justify="space-around">
            <v-avatar size="150">
              <img
                src="../assets/self.jpg"
                alt="Momentum"
              >
            </v-avatar>
					</v-row>
					<div class="flex headline font-weight-light text-center">Chaikamon Treephong</div>
					<v-divider class="mx-4"></v-divider>
          <v-list dense class="mylist">
            <v-list-item>
              <v-list-item-icon >
                <v-icon color="gray">mdi-cake-variant</v-icon>
              </v-list-item-icon>
              <v-list-item-subtitle>April 5, 1987</v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon color="gray">mdi-email-outline</v-icon>
              </v-list-item-icon>
              
              <v-list-item-subtitle>jpmomentum@gmail.com</v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon color="gray">mdi-cellphone-android</v-icon>
              </v-list-item-icon>
              <v-list-item-subtitle>(66) 85-8635662</v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon color="gray">mdi-map-marker-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-subtitle>Bangkok, Thailand</v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon color="gray">mdi-linkedin</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-subtitle><a v-bind:href="linkedin" target="_blank">{{ linkedin }}</a></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
					<v-divider class="mx-4"></v-divider>
          <language/>
					<v-divider class="mx-4"></v-divider>
          <interest/>
				</v-card>
			</v-col>
			<v-col sm="8">
				<div>
					<v-card 
						class="mx-0" 
						tile
						outlined
						width="100%"
						height="100%"
					>
						<aboutme/>
						<v-divider class="mx-4"></v-divider>
            <education/>
            <v-divider class="mx-4"></v-divider>
            <experience/>
            <v-divider class="mx-4"></v-divider>
            <skill/>
					</v-card>
				</div>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import interest from './interest'
import language from './languange'
import aboutme from './about'
import education from './education'
import experience from './experience'
import skill from './skill';

export default {
  components: {
    interest,
    language,
    aboutme,
    education,
    experience,
    skill
  },
  data: () => ({
    linkedin : "https://www.linkedin.com/in/jpmomentum"
})
}
</script>

<style scoped>
  @import '../assets/css/common.css';
  .mylist > * {
    margin-left: 0px;
  }
  .infocontainer {
    max-width:1280px; 
    padding: 12px 12px 0px 12px;
  }
</style>
