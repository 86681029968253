<template>
	<v-container style="padding:0px;">
		<v-card-title>EDUCATION</v-card-title>
		<v-row v-for="(item, i) in educations" :key="i">
			<v-col sm="6" class=pt-0>
				<v-list-item >
					<v-list-item-content class="py-0">
						<v-list-item-title>{{ item.title }}</v-list-item-title>
						<v-list-item-subtitle>{{ item.subtitle1 }}</v-list-item-subtitle>
						<v-list-item-subtitle>{{ item.subtitle2 }}</v-list-item-subtitle>
					</v-list-item-content>
				</v-list-item>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
export default {
	data: () => ({
		educations: [
			{
				"title": "Chiang Mai University",
				"subtitle1": "Master’s Degree, Computer Engineering, 3.52",
				"subtitle2": "2011 - 2013"
			},
			{
				"title": "Chiang Mai University",
				"subtitle1": "Bachelor’s Degree, Computer Science, 2.51",
				"subtitle2": "2005 - 2009"
			}
		]
		
	})
}
</script>