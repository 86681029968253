<template>
	<v-container style="padding:0px;">
    <v-card-title>
      HOBBIES / INTERESTS
    </v-card-title>
    <div>
      <v-chip class="ma-1" v-for="(item, tag) in tags" :key="tag" outlined>
        <v-icon left>{{ item.icon }}</v-icon>
        {{ item.title }}
      </v-chip>
    </div>
  </v-container>
</template>
<script>
export default {

  data: () => ({
    tags: [
      {
        title: "Soccer",
        icon: "mdi-soccer"
      },
      {
        title: "Lego",
        icon: "mdi-toy-brick"
      },
      {
        title: "Music",
        icon: "mdi-music"
      },
      {
        title: "Games",
        icon: "mdi-gamepad-variant-outline"
      },
      {
        title: "Coding",
        icon: "mdi-code-json"
      },

      {
        title: "Travelling",
        icon: "mdi-wallet-travel"
      },

      {
        title: "Work-Life balance",
        icon: "mdi-scale-balance"
      },
      {
        title: "Movies",
        icon: "mdi-filmstrip-box"
      },
      {
        title: "Foods",
        icon: "mdi-food"
      }
    ]
})
}
</script>