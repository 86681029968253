<template>
  <v-container class="timelinecontainer">
    <v-row style="background-color:white;">
    <v-card
    width="100%"
    color="grey lighten-4"
    light
  >
     <v-card-title class="pa-2 indigo lighten-5">
      <h3 class="title font-weight-light text-center grow">Timeline</h3>
      <v-switch class="font-weight-light" color="primary" v-model="timelineOrderNewest"  label="Reverse" @change="reverseTimelineItems()"></v-switch>
    </v-card-title>
    <v-card-text>
        <v-timeline
          dense
        >
          <v-timeline-item
            v-for="(item, i) in items"
            :key="i"
            :icon="item.icon || ''"
            :color="item.color"
            :class="{transparent: item.transparent}"
            large
          >
            <template
              v-if="item.iconImage"
              v-slot:icon
            >
              <v-avatar>
                <img
                  :src="publicPath(item.iconImage)"
                >
              </v-avatar>
            </template>
            <template v-slot:opposite />
            <v-layout>
              <v-flex
                v-if="$vuetify.breakpoint.smAndUp"
                md1
                sm2
                align-self-center
              >
                <span>{{ item.year }}</span>
              </v-flex>
              <v-flex
                md11
                sm10
                xs12
              >
                <v-card class="elevation-1">
                  <v-card-title class="px-3 pb-0">
                    <div>
                      <p v-if="$vuetify.breakpoint.xsOnly">
                        {{ item.year }}
                      </p>
                      {{ item.title }}
                    </div>
                  </v-card-title>
                  <v-card-text class="px-3 pt-0">
                    <v-layout wrap>
                      <v-flex
                        :md7="!!item.image"
                        :md12="!item.image"
                        xs12
                      >
                        <v-list class="pa-0">
                          <template v-for="(detail, j) in item.details">
                            <v-list-item class="pa-0"
                              :key="j"
                            >
                              <v-list-item-icon class="mr-2">
                                <v-icon>{{ detail.icon }}</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <span >{{ detail.title }}</span>
                                <p class="mb-0" style="color:rgba(0, 0, 0, 0.6);"
                                  v-for="(subtitle, k) in detail.subtitles"
                                  :key="k"
                                >
                                  •	{{ subtitle }}
                                </p>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-list>
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>
          </v-timeline-item>
        </v-timeline>
    </v-card-text>
  </v-card>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    data: () => ({
      align: [
        "start",
        "end",
        "center",
        "baseline",  
        "stretch",
      ],
      timelineOrderNewest : false,
      items: [
        {
          title:"First Day",
          color: "grey",
          year: "1987",
          icon: "mdi-cake-variant",
          details: [
            { 
              icon: "mdi-cake-variant",
              title: "Born on April 5, 1987",
              subtitles: [],
            }
          ]
        },
        {
          title:"Attended the University",
          color: "grey",
          year: "2005",
          icon: "fas fa-university",
          details: [
            { 
              icon: "fas fa-atom",
              title: "Studied in the Faculty of Science, Chiang Mai University",
              subtitles: [
                "Computer Science Program"
              ],
            }
          ]
        },
        {
          title:"Internship",
          color: "grey",
          year: "2008",
          icon: "fas fa-laptop",
          details: [
            { 
              icon: "fas fa-asterisk",
              title: "Internship at CT Asia Co., Ltd.",
              subtitles: [
                "Joined the company as an intern, responsible for the study and testing of a new IP-PBX Phone System. As well as programming assignments."
              ],
            }
          ]
        },
        {
          title:"Graduated With a Bachelor's Degree",
          color: "grey",
          year: "2009",
          icon: "fas fa-graduation-cap",
          details: [
            { 
              icon: "fas fa-microscope",
              title: "Independent Study (Final Project)",
              subtitles: [
                'The independent study was the implementation in topic "Temperature Control System with Embedded System on Bluetooth Network"'
              ]
            },
            { 
              icon: "fas fa-atlas",
              title: "Minor",
              subtitles: [
                "I selected to study minoring in Geography. The specific field is Remote Sensing and GIS"
              ],
            },
            { 
              icon: "fas fa-user-graduate",
              title: "GPA",
              subtitles: [
                "2.51"
              ],
            }
          ]
        },
        {
          title:"Work and Travel",
          color: "grey",
          year: "2009",
          icon: "fas fa-plane-departure",
          details: [
            { 
              icon: "mdi-castle",
              title: "Spent summertime at New Jersey, USA. Worked at Morey's Piers",
              subtitles: [
                "Responsible for operate the ride in the park, including inspecting and cleaning"
              ],
            }
          ]
        },
        {
          title:"First Job, A Junior Programmer at Nim See Seng Transport 1988 Co., LTD.",
          color: "grey",
          year: "2009 - 2011",
          icon: "fas fa-laptop",
          details: [
            { 
              icon: "fab fa-java",
              title: "Developed In-house software, using VB6 and Java.",
              subtitles: [
                "Developed and maintain Windows Form Application for internal users, as well as created new simple reports",
                "Developed web application based on existing features from windows form application",
                "Assisted in the daily report project"
              ],
            }
          ]
        },
        {
          title:"Career Change, Started work at Mycos Technologies",
          color: "grey",
          year: "2011 - 2014",
          icon: "fas fa-laptop",
          details: [
            { 
              icon: "fas fa-dna",
              title: "Collaborated with the Bioinformatics project",
              subtitles: [
                "Responsible for created perl scripts to run the bioinformatics tools",
                "Managing data processing on AWS EC2"
              ],
            }
          ]
        },
        {
          title:"Attended the University (Again)",
          color: "grey",
          year: "2011",
          icon: "fas fa-university",
          details: [
            { 
              icon: "fas fa-laptop-code",
              title: "Studied in the Faculty of Engineering, Chiang Mai University",
              subtitles: [
                "Computer Engineering Program (Master degree)"
              ],
            }
          ]
        },
        {
          title:"Graduated with a Master's Degree",
          color: "grey",
          year: "2013",
          icon: "fas fa-graduation-cap",
          details: [
            { 
              icon: "fas fa-microscope",
              title: "Independent Study (Final Project)",
              subtitles: [
                'The independent study (Final Project) was to study and research in topic "Efficiency Improvement for ChIP-Sequencing Using MapReduce"'
              ],
            },
            { 
              icon: "fas fa-table",
              title: "Selection Course (Focuses on Data Mining and Analytics)",
              subtitles: [
                "Implemented web-based data visualization tool for create interactive 2D heatmap and hierarchical clustering from input matrix",
                "Implemented web-based classification tool (decision tree and Bayesian) for calculate classes from input data matrix"
              ],
            },
            { 
              icon: "fas fa-user-graduate",
              title: "GPA",
              subtitles: [
                "3.52"
              ],
            }
          ]
        },
        {
          title:"Moved to work in another project at Mycos",
          color: "grey",
          year: "2014 - 2019",
          icon: "fas fa-laptop",
          details: [
            { 
              icon: "mdi-finance",
              title: "Outsourcing for the fintech start-up company",
              subtitles: [
                "Responsible for maintaining the existing in-house software",
                "Developed automation tools and logic to get raw data from targeted websites (Web Scraping)",
                "Developed web API using .Net framework",
                "Developed the automated client socket application to send, receive and monitoring data with external service provider",
              ],
            }
          ]
        },
        {
          title:"Side Project at Mycos",
          color: "grey",
          year: "2018",
          icon: "fas fa-laptop",
          details: [
            { 
              icon: "fas fa-tools",
              title: "Outsourcing for the auto parts price comparison business start-up company",
              subtitles: [
                "Developed Self-Host web API application for searching products and prices from multiple websites (Upgradable to online service)"
              ],
            }
          ]
        },
        {
          title:"Started work at KTB",
          color: "grey",
          year: "2019 - Now",
          icon: "fas fa-laptop",
          details: [
            { 
              icon: "fas fa-cloud",
              title: "Working in the innovation team, A lot of chalenges at KTB",
              subtitles: [
                "Develop Microservices to serve mobile banking application",
                "Assisted in the system design and migrated application from on premise to cloud system",
                "Develop scripts for data reconciliation and remove old data on daily and monthly basis, as well as logging all actions",
                "Developed OpenID login page, login services and config OAuth 2.0 Server, with the new Open APIs",
                "Design query for full text search to get results which are close to user expectations",
                "Responsible for code review and mentor for junior developers",
              ],
            }
          ]
        }
      ],
    }),
    methods: {
      reverseTimelineItems: function () {
        this.items.reverse()
        this.$nextTick(() => {
          this.$forceUpdate();
        });
        
      }
    }
  }
</script>

<style scoped>
  .timelinecontainer {
    max-width:1280px; 
    padding: 0px 12px 12px 12px;
  }
</style>